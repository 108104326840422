<template>
  <div class="modal fade"  id="numericPad" tabindex="-1" aria-labelledby="numericPad" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" ref="closeModal" class="d-none" data-bs-dismiss="modal">Close</button>
        <div class="keypad-container">
        <div class="keypad-value">
          <el-input
            class="mt-2"
            v-model="number"
            readonly
            placeholder="Rp 0">
          </el-input>
        </div>
        <template v-for="n in 12">
          <div :key="n" class="keypad-flex  keypad-class">
            <div 
              class="keypad" 
              v-if="n == 10 && onReset" 
              @click="onReset">
              <div class="keypad-center">
                C
              </div>
            </div>
            <div
              class="keypad"
              :ripple="true"
              v-if="n != 10 && n != 12"
              @click="onInput(n)">
              <div v-if="n < 10" class="keypad-center">
                {{ n }}
              </div>
              <div v-if="n == 11" class="keypad-center">
                0
              </div>
            </div>
            <div 
              class="keypad" 
              v-if="n == 12 && onDelete" 
              @click="onDelete(n)">
              <div v-if="n == 12" class="keypad-center">
                <img src="@/assets/icons/delete-icon.svg" alt="Delete">
              </div>
            </div>
          </div>
        </template>

        <div class="d-flex w-100 py-3 gap-3">
            <button 
              class="
              btn 
              btn-outline-danger 
              font-nunito font-16 font-700
              py-3 px-4"
              @click="closeNumpad()">
              Batalkan
            </button>
          <div class="flex-fill ">
            <button 
              class="
              btn 
              btn-primary
              text-white
              font-nunito font-16 font-700
              py-3 w-100"
              @click="addExtras()">
              Tambahkan
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import IhsMixin from '@/mixins/ihs.mixins.js'

import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  mixins: [IhsMixin],
  data: () => ({
    n: 0,
    number: '',
    maxLength: 12,
  }),
  computed: {
    ...mapState('numpad', ['numpadVisibility'])
  },
  methods: {
    ...mapMutations('numpad', ['SET_EXTRA_VALUE']),
    ...mapMutations('cart', ['SET_CART_EXTRA_VALUE']),
    ...mapActions('cart', ['sendAdditionalCost']),

    async addExtras(){
      let extraValue = this.number.replace(/\D/g, "")
      if(extraValue==''){extraValue = 0}
      this.SET_EXTRA_VALUE(parseInt(extraValue))
      this.SET_CART_EXTRA_VALUE(parseInt(extraValue))
      await this.sendAdditionalCost(extraValue)
      this.$refs.closeModal.click()
      this.number = ''
    },
    closeNumpad() {
      this.$refs.closeModal.click()
      this.number = ''
    },
    onInput(key) {
      if(key === 11){key=0}
      this.number = this.formatRupiah((this.number + key).slice(0, this.maxLength), 'Rp. ')
    },
    onDelete() {
      this.number = this.number.slice(0, this.number.length - 1)
      if(this.number.length <= 3){
        this.number = ''
      }
    },
    onReset() {
      this.number = ''
    }
  },
};
</script>

<style lang="scss">
.keypad-value{
  .el-input__inner{
    text-align: right;
    border: 1px solid #DCDFE6 !important;
    font-size: 20px;

    &::placeholder {
      color: black;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
    }
  }
}
</style>

<style lang="scss" scoped>
.hide {
  visibility: hidden !important;
}

.keypad-class {
  color: #000000;
  background: #FAFAFA;
  border: 1px solid #DADADA;
}

.keypad-class-1{
  border-radius: 6px 0px 0px 0px;
}
.keypad-class-3{
  border-radius: 6px 0px 0px 0px;
}

.keypad-dialog {
  position: absolute;
  bottom: 0;
  width: 483px;
  background: white;
  padding: 0 18px 0 24px;
  z-index: 1060;
}

.keypad-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 0;
  flex-direction: row;

  .btn-outline-danger{border: 2px solid !important;}
}

.keypad-value {
  width: 100%;
  text-align: right;
  padding: 1rem 0;
}

.keypad-flex {
  flex-basis: 33%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 33%;
  min-height: 4rem;
}

.keypad {
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: center;
  margin: 0 auto;
}

.keypad-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.3rem;
}

.keypad-delete {
  font-size: 1.5rem;
}

.keypad-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: #00000066;
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
</style>
