<template>
  <div class="product-list col-md-8 "  
   >
    <div class="content-spacing">
      <product-buy @resetPage="resetPage" />
      <add-to-cart />
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'

import { mapState, mapActions, mapMutations } from 'vuex'
import ProductBuy from '@/components/product/ProductBuy.vue'

// import LoadMore from '@/components/partials/LoadMore.vue'

import AddToCart from '@/components/product/popup/AddToCart.vue'

// import ProductListSkeleton from '@/components/skeleton/ProductListSkeleton.vue'

export default {
  mixins: [IhsMixin],

  components: {
    ProductBuy,
    // LoadMore,

    AddToCart,

    // ProductListSkeleton
  },
data() {
    return {
      disable: false,
      pageSize: 12,
      page: 0,
      productKeyword: '',
      loadMoreLoading: false,
      selectedSort: {
        name: 'Terbaru',
        type: 'newest'
      },
    }
  },  
  computed: {
    ...mapState('home', [
      'sidebarItemActive',
      'filterProduct',
      'productCategorySelected',
      'filterProductItem',
    ]),
    ...mapState('product', ['products', 'productLoading']),
    filterQuery() {
      return this.$route.query
    }
  },
  methods: {
    ...mapActions('product', ['getProducts', 'getProduct']),
    ...mapMutations('product', ['SELECT_PRODUCT', 'SET_PRODUCT_LOADING', 'SET_LOAD_MORE_PRODUCT_LOADING']),
    ...mapMutations('home', ['setFilterProduct']),
    resetPage(filter) {
      this.page = 0
      filter()
    },
    async getProductList() {
        this.page++
        if(this.page === 1) {
            this.SET_PRODUCT_LOADING(true)
        } else {
            this.SET_LOAD_MORE_PRODUCT_LOADING(true)
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout( async () => {
          try {
            const payload = {
              params: {
                search: this.filterQuery.keyword ? this.filterQuery.keyword : '' ,
                filter: this.filterQuery.sort ? this.filterQuery.sort : this.selectedSort.type ,
                category: this.filterQuery.category ? this.filterQuery.category : '' ,
                page: this.page,
                perPage: 8
              },
            }
            await this.getProducts(payload)
        
          } catch (error) {
              this.$message({
                showClose: true,
                customClass: "error-message",
                message: error.response.data.message,
                type: "error"
              });
          }
        }, 1000);    
    },
  },
  async created() {
    // await this.getProductList()
  }

}
</script>

<style lang="scss">
.product-list{
   height: 100vh;
  .card-product{
    cursor: pointer;
    padding: 16px;
    background: white;
    border-radius: 6px;
    height: 100%;

    img{
      width: 102px;
      border-radius: 6px;
    }

    .description{
      span{display: block;}
    }
  }

  .dropdown-menu{
    top: 5px !important;
    left: -15px !important;
  }
}
</style>

<style lang="scss" scoped>
.content-spacing{
  padding: 24px 24px 0 24px;
}
</style>