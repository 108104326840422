<template>
  <div class="d-flex flex-column font-roboto font-14 w-100 cart-summary-container">
    <div class="d-flex mb-2 pt-3">
      <div>
        <span>Subtotal {{ productTotal }} Produk</span>
      </div>
      <div class="ms-auto">
        <span>{{ formatRupiah(subtotal, 'Rp') }}</span>
      </div>
    </div>
    <!-- <div v-if="discount !== 0" class="d-flex mb-2">
        <div>
          <span>Potongan Harga</span>
        </div>
        <div class="ml-auto">
          <span>-{{formatRupiah(discount, 'Rp')}}</span>
        </div>
      </div> -->
    <div v-if="Object.keys(voucher).length" class="d-flex mb-2 font-14 font-400">
      <div>
        <span class="color-blue-dark-50">{{ voucher.code }}</span>
      </div>
      <div class="ms-auto">
        <span>-{{ formatRupiah(voucherAmmount, 'Rp') }}</span>
      </div>
    </div>
    <div v-if="usePoint" class="d-flex mb-2 font-14 font-400">
      <div>
        <span>Potongan Poin</span>
      </div>
      <div class="ms-auto">
        <span>-{{ formatRupiah(discountPoint, 'Rp') }}</span>
      </div>
    </div>
    <div v-if="extraValue" class="d-flex mb-2">
      <div>
        <span>Lainnya</span>
      </div>
      <div class="ms-auto">
        <span>{{ formatRupiah(extraValue, 'Rp') }}</span>
      </div>
    </div>
    <div class="d-flex mt-1 mb-2 font-16 font-500">
      <div>
        <span>Total</span>
      </div>
      <div class="ms-auto">
        <span>{{ usePoint ? formatRupiah(finalPrice - discountPoint, 'Rp') : formatRupiah(finalPrice, 'Rp') }}</span>
      </div>
    </div>
    <div class="d-flex pb-2" v-if="finalPrice >= 25000 && customerSelected.phone_number != 111000">
      <span class="ms-auto font-400 font-14 font-roboto">
        <span class="font-500 color-primary">{{ usePoint ? Math.floor((finalPrice - discountPoint) / 25000) * 250 :
          Math.floor(finalPrice / 25000) * 250 }}</span> poin akan didapatkan</span>
    </div>
    <div class="d-flex justify-content-between pb-2" v-if="customerSelected.phone_number != 111000">
      <div class="flex align-items-center" :class="{ 'no-point': !customerSelected.point }">
        <img src="@/assets/icons/point-icon.svg" class="me-2" alt="point">
        <span class="font-14 font-400">
          <span class="font-700">Tukar Poin</span> ({{ formatRupiah(discountPoint, 'Rp. ') }})
        </span>
      </div>
      <el-switch :disabled="!customerSelected.point || finalPrice <= 10000" :value.sync="usePoint"
        @change="changeUsePoint" active-color="#6ACDEC">
      </el-switch>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import IhsMixin from '@/mixins/ihs.mixins.js'

export default {
  mixins: [IhsMixin],
  watch: {
    discountPoint: {
      immediate: true,
      handler(value) {
        if (this.usePoint) {
          this.setPaymentUsePoint(value)
          this.SET_CALCULATED_POINT(value)
        }
      }
    },
    extraValue: {
      immediate: true,
      handler(value) {
        this.SET_CART_EXTRA_VALUE(value)
      }
    },
    finalPrice: {
      immediate: true,
      handler(value) {
        if (value <= 10000) {
          this.changeUsePoint(false)
        }
      }
    }
  },
  computed: {
    ...mapState('cart', ['voucher', 'extraValue']),
    ...mapState('user', ['customerSelected']),
    ...mapState('payment', ['usePoint']),
    ...mapGetters('cart', [
      'productTotal',
      'subtotal',
      'discount',
      'total',
      'voucherAmmount',
      'finalPrice'
    ]),
    discountPoint() {
      if ((this.finalPrice - this.customerSelected.point) <= 10000) {
        return this.finalPrice - 10000
      } else {
        return this.customerSelected.point
      }
    }
  },
  methods: {
    ...mapActions('payment', ['setPaymentUsePoint']),
    ...mapMutations('payment', ['SET_USE_POINT']),
    ...mapMutations('user', ['SET_CALCULATED_POINT']),
    ...mapMutations('cart', ['SET_CART_EXTRA_VALUE']),
    async changeUsePoint(value) {
      this.SET_USE_POINT(value)
      this.SET_CALCULATED_POINT(this.discountPoint)

      if (value) {
        await this.setPaymentUsePoint(this.discountPoint)
      } else {
        await this.setPaymentUsePoint(0)
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.cart-summary-container {
  border-top: 1px solid #DADADA;
}

.no-point {
  opacity: 0.5;
}
</style>