<template>
  <div 
    class="modal fade" 
    id="PopupAddToCart"
    tabindex="-1" 
    ref="modal"
    aria-labelledby="PopupAddToCart" 
    aria-hidden="true"
    data-bs-backdrop="false">
    <button data-bs-toggle="modal"
		:data-bs-target="'#PopupAddToCart'" class="d-none" ref="openModal"></button>
    <div 
    class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span 
            class="font-nunito font-18 font-700" 
            id="exampleModalLongTitle">
            Tambahkan Produk 
          </span>
          <button type="button"  ref="closeModalref" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <el-skeleton style="width: 100%"  :loading="productSelectedLoading" animated>
            <template slot="template">
              <div class="d-flex justify-content-between align-items-center">
                 <div class="product-image d-flex gap-2" v-lazy-container="vLazyProductImage">
                    <el-skeleton-item variant="image" style="width: 64px; height: 64px;" />
                      <div class="flex-shrink-1 product-info my-auto d-flex flex-column">
                         <el-skeleton-item variant="text" style="width: 200px;" />
                          <el-skeleton-item class="mt-3" variant="text" style="width: 80px;" />
                      </div>
                  </div>
                <el-skeleton-item variant="text" style="width: 100px;" />
              </div>
              <div class="mt-4 d-flex flex-column">
                  <el-skeleton-item variant="h1" style="width: 130px;" />
                  <el-skeleton-item class="mt-3" variant="text" style="width: 100px;" />
              </div>
            </template>
            <template>
              <div 
                v-if="!productSelectedLoading" 
                class="d-flex flex-column ">
                  <div class="d-flex justify-content-between align-items-center gap-2">
                      <div class="product-image d-flex gap-2" v-lazy-container="vLazyProductImage">
                        <img :data-src="productSelected.files[0].value" class="my-auto">
                        <div class="flex-shrink-1 product-info my-auto">
                          <span class="font-16 font-500 font-roboto">
                            {{productSelected.type === 'simple' ? productSelected.details.name : productSelected.name}}
                          </span>
                        </div>
                      </div> 
                      
                      <span class="product-price">
                        {{
                          formatRupiah(
                             productSelected.type === 'combination' && Object.keys(variant).length ? variant.discounted_price : productSelected.min_discounted_price,
                            'Rp'
                          )
                        }} 
                        <template v-if="!Object.keys(variant).length && productSelected.min_discounted_price !== productSelected.max_discounted_price">
                          - {{ formatRupiah(productSelected.max_discounted_price, 'Rp') }}
                        </template>
                      </span>    
                  </div>
                  <template v-if="productSelected.type === 'combination'">
                    <product-variants/>
                  </template>
                  
                  <div class="d-flex justify-content-between mt-4">
                    <div >
                      <span class="font-roboto font-16 font-500">
                        Jumlah yang dibeli
                      </span>
                      <div class="font-roboto font-14 color-grey-100 font-400">
                        Stock Tersedia: 
                        
                        {{
                          productSelected.type === 'simple' ?
                          productSelected.total_stock : Object.keys(variant).length ? variant.stock : productSelected.total_stock
                        }}
                      </div>
                    </div>

                    <div class="input-counter my-auto ml-auto">
                      <el-input-number 
                        class="pane-count__counter" 
                        v-model="counter" 
                        :disabled="productSelected.type === 'simple' ? !productSelected.total_stock : Object.keys(variant).length ? !variant.stock : true "
                        :min="1" :max=" productSelected.type === 'simple' ?
                          productSelected.total_stock : Object.keys(variant).length ? variant.stock : productSelected.total_stock"
                      />
                    </div>
                  </div>
                </div>
            </template>
          </el-skeleton>
         
      
        </div>
        
        <div class="modal-footer">
           <el-skeleton style="width: 100%"  :loading="productSelectedLoading" animated >
            <template slot="template">
              <el-skeleton-item class="mt-3" variant="button" style="width: 100%;" />
            </template>
            <template>
              <button 
                class="button button--primary py-2 w-100" 
                :disabled="!isVariantSelectedAll"
                @click="add(productSelected , counter)">
                <span class="font-nunito font-16 font-700">
                  Tambah Pesanan
                </span>
              </button>
            </template>
           </el-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'

import { mapState, mapActions ,mapMutations, mapGetters } from 'vuex'

import ProductVariants from '@/components/product/partials/ProductVariants.vue'

export default {
  mixins: [IhsMixin],
  components: {
    ProductVariants
  },
  data() {
    return {
      modal: '',
      counter: 0
    }
  },
  watch: {
    variant() {
      this.counter = 0
    }
  },
  computed: {
    ...mapState('product', [
      'productSelected', 
      'productVariant',
      'productSelectedLoading',
      'variant'
    ]),
    ...mapState('shift', ['shiftId']),
    ...mapState('user', ['customerSelected']),
    ...mapState('cart', ['cart']),
    ...mapGetters('product', [
      'productImageCombination', 
      'productStockCombination', 
      'productPriceCombination'
    ]),
      isVariantSelectedAll() {
        if (this.productSelected.type === 'combination') {
          return Object.keys(this.variant).length && this.variant.stock
        } else {
          return this.productSelected.total_stock
        }
    },
    checkStock () {
      if(this.productSelected.type === 'combination') {
        if(Object.keys(this.variant).length) {
           if(this.variant.stock) {
              return false
          } else {
            return true
          }
        } else {
          if(this.productSelected.details.stock) {
              return false
          } else {
            return true
          }
        }
      } 

      return false

    }
  },
  methods: {
    ...mapActions('cart', ['addCart']),
    ...mapActions("product", ["getProduct"]),
    ...mapMutations('product', [
      'SELECT_PRODUCT',
      'RESET_PRODUCT_VARIANT',
      'RESET_VARIANT',
      'SET_SELECT_PRODUCT_LOADING'
    ]),
    ...mapMutations('cart', ['SET_CART', 'SET_VOUCHER', 'ADD_GUEST_CART', ]),

   async add(productSelected, counter){
    try {
      if(productSelected.type === 'simple') {
       const payload = {
        data: {
          product_detail_id: productSelected.details.id,
          quantity: counter,
          phone_number: this.customerSelected.phone_number
        },
      }

      const response = await this.addCart(payload)

      if(response.data.status_code === 200) {
           this.$message({
            message: 'Produk Berhasil Ditambahkan Ke Keranjang',
            type: 'success'
          });
        }
    
     } 
     else if (productSelected.type === 'barcode') {
         if (this.shiftId) {
            const payload = {
                data: {
                  barcode: productSelected.id,
                  quantity: counter,
                  phone_number: this.customerSelected.phone_number
                },
            }

          const response = await this.addCart(payload)
        
          if(response.data.status_code === 200) {
              this.$message({
                message: 'Produk Berhasil Ditambahkan Ke Keranjang',
                type: 'success'
              });
            }
        } else {
          this.$message({
            showClose: true,
            message: 'Mohon mulai shift terlebih dahulu',
            type: 'warning'
          });
      } 
     }
     else {
         const payload = {
          data: {
            product_detail_id: this.variant.id,
            quantity: counter,
            phone_number: this.customerSelected.phone_number
          }
        }
        
        const response = await this.addCart(payload)

        if(response.data.status_code === 200) {

           this.$message({
            message: 'Produk Berhasil Ditambahkan Ke Keranjang',
            type: 'success'
          });
        }
     }
      
    this.$refs.closeModalref.click()

    } catch (error) {
      if (error.response.data.status_code === 422 && productSelected.type === 'barcode' ) {
           this.$message({
            type: 'error',
            showClose: true,
            message: 'Product Dengan Barcode Tersebut Tidak Dapat Ditemukan',
          });
        } else if (error.response.data.status_code === 300) {
          await this.getProduct(error.response.data.data.product.slug);
          await this.$refs.openModal.click()
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });
        }
    }
    
   },
    closeModal(){
      this.counter = 0
      this.SELECT_PRODUCT('')
      this.SET_SELECT_PRODUCT_LOADING(true)
      this.RESET_PRODUCT_VARIANT()
      this.RESET_VARIANT()
    },
      async onBarcodeScanned (barcode) {
          
        const payload = {
          type: 'barcode',
          id: barcode
        }
        await this.add(payload, 1)
      },
    
  },
  mounted () {
    const _this = this
    const modal = document.getElementById('PopupAddToCart')
    modal.addEventListener('hidden.bs.modal', function () {
      _this.closeModal()
    })
    this.$barcodeScanner.init(this.onBarcodeScanned)

  },

}
</script>

<style lang="scss">
.input-counter{
  .el-input-number{
    width: 125px;
  }
}
</style>

<style lang="scss" scoped>
.modal-footer{
  padding-top: 0px;
}
.product-price {
  min-width: 90px;
}
</style>