<template>
  <fragment>
    <div  class="col-12 col-lg-4  position-fixed end-0 top-0 ps-4 vh-100" style="padding-top: 78px;">
      <div class="cart-container d-flex flex-column bg-white overflow-hidden">
          <shift-timer class="mb-3" />
          <cart-content />
      </div>
    </div>
    <customer />
    <customer-form />
    <customer-history/>
    <numeric-pad />
    <popup-qr/>
    <product-price />
    <PopupEmptyStockProduct />
  </fragment>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

import ShiftTimer from '@/components/shift/partials/ShiftTimer.vue'
import { Fragment } from 'vue-fragment'


import CartContent from '@/components/cart/CartContent.vue'

import Customer from '@/components/customer/popup/Customer.vue'
import CustomerHistory from '@/components/customer/popup/CustomerHistory.vue'
import CustomerForm from '@/components/customer/popup/CustomerForm.vue'
import ProductPrice from '@/components/product/popup/Price.vue'

import PopupQr from '@/components/popup/Qr.vue'
import PopupEmptyStockProduct from '@/components/cart/popup/EmptyStockProduct.vue'

import NumericPad from '@/components/popup/NumericPad.vue'

export default {
  components: {
    ShiftTimer,
    CartContent,
    Fragment,
    Customer,
    ProductPrice,
    CustomerForm,
    CustomerHistory,
    PopupQr,
    NumericPad,
    PopupEmptyStockProduct
  },
  computed: {
    ...mapState('shift', ['shift']),
    ...mapState('cart', ['cartList', 'cartUser']),
    ...mapState('user', ['customerSelected']),
    ...mapGetters('cart', ['getCartList']),
    ...mapGetters('user', ['getCustomerSelected'])
  },
  methods: {
    ...mapActions('shift', ['endShift']),
    ...mapMutations('cart', [
      'SET_CART',
      'SET_VOUCHER',
      'RESET_CART',
      'RESET_CART_USER',
      'RESET_VOUCHER'
    ]),
    ...mapMutations('user', ['RESET_SELECTED_CUSTOMER']),
  },
  created() {
    
  },
}
</script>

<style lang="scss" scoped>
  .cart-container {
    height: calc(100vh - 80px);
  }
</style>